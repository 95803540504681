button {
    min-width: 75%;
}

.root {
    padding: 100px 0px 0px 0px;
    height: 100vh
}

.header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
}

#title {
    font-size: 2rem;
} 
@media (min-width: 600px) {
    #title{
        font-size: 4rem;
    } 
}
@media (min-width: 1200px) {
    #title{
        font-size: 6rem;
    } 
}


.buttonCol {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    margin: 10px;
    width: 10em;
}

.mobileButton {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 50%;
    margin: 0px auto 4% auto;
}

.twitter-container {
    margin-left: auto;
    margin-right: auto;  
    margin-top: 40px;   
    font-size: 15px;
}