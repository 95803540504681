.contentFrame {
    box-sizing: border-box;
    margin-top: 20px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .contentText {
    padding: 10px 10px 10px 10px;
  }  
  
@media (min-width: 600px) {
    .contentFrame{
        margin-top: 80px;
    } 
}
@media (min-width: 1200px) {
    .contentFrame{
        margin-top: 100px;
    } 
}
